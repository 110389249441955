.contacts {
    &--smaller {
        font-size: 80%;
        margin-top: 20px;
    }

    &__content {
        display: flex;
        margin-bottom: 50px;
    }

    &__left-part {
        margin-right: 80px;
    }
}
