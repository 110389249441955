@import "../../utils.scss";

.file-link {
    cursor: default;
    text-decoration: none;
    color: #AAA;
    margin: 30px 0;

    &::selection {
        background: transparent;
    }

    &--active {
        color: black;
        cursor: pointer;
    }

    &__pointer {
        opacity: 0;
        @include prefix(transition, opacity 150ms linear, webkit moz o ms);

        &--active {
            opacity: 1;
        }
    }

    &__text-big {
        font-size: 40px;
    }

    &__text-description {
        font-size: 25px;
    }
}
