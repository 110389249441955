@mixin prefix($property, $value, $prefixes: ()) {
    #{$property}: $value;
    @each $prefix in $prefixes {
        #{"-" + $prefix + "-" + $property}: $value;
    }
}

@mixin colorTransition {
    @include prefix(transition, color 100ms linear, webkit moz o ms);
}
