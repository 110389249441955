.logo {
    height: 200px;
    width: 100%;
    color: white;
    text-align: center;
    font-weight: 300;

    &__background {
        object-fit: cover;
        width: inherit;
        height: inherit;
    }

    &__title {
        position: relative;
        top: -165px;
        display: inline-block;

        font-size: 100px;
        text-shadow: 0px 0px 5px #000;
    }

    &__subtitle {
        position: relative;
        top: -20px;
        font-size: 30px;
        text-align: right;
        text-shadow: 0px 0px 2px #000;
    }
}
