html {
    overflow-y: scroll;
}

body {
    display: flex;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    background-color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-size: 30px;
    font-weight: 400;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: inherit;
    color: inherit;
}

* {
    &:focus {
        outline: none;
    }
    &::selection {
        color: white;
        background: #AAA;
    }
}

h1 {
    font-weight: 300;
    font-size: 70px;
    color: #CCC;
    margin: 20px 0 10px 0;
}

h2 {
    font-weight: 600;
    font-size: 40px;
    color: #333;
    margin: 30px 0 20px 0
}

.container {
    margin: 0 10px 10px 10px;
    min-width: 800px;
    max-width: 1200px;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}