@import "../../utils.scss";

$size: 200px;

.supervisor-badge {
    display: inline-block;
    margin: 10px 10px 80px 10px;
    width: $size;
    height: $size;
    text-align: center;

    &::selection {
        background: transparent;
    }

    &__image-container {
        width: $size;
        height: $size;
        position: relative;
    }

    &__image,
    &__hover-image {
        width: $size;
        height: $size;
        border-radius: 50%;
        box-sizing: border-box;
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;

        &:not(&--disabled) {
            animation-name: pulse;
            animation-direction: alternate-reverse;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }
    }

    &__hover-image {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        @include prefix(transition, all 250ms linear, webkit moz o ms);

        &:hover:not(&--disabled) {
            opacity: 1;
            box-shadow: 0 0 0 2px #FFF, 0 0 0 6px #000;
        }
    }

    &__title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 25px;
        padding-top: 10px;
    }
}

@keyframes pulse {
    from {
        box-shadow: 0 0 0 2px #FFF, 0 0 0 4px #00000070;
    }

    to {
        box-shadow: none;
    }
}
