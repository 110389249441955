.year {
    font-size: 30px;

    &__left-part {
        color: #AAA;
        margin-right: 40px;
    }

    &__row {
        display: flex;
        align-items: baseline;
    }

    &__counter-description {
        margin-left: 20px;
    }

    &__countdown-description {
        margin-right: 15px;
    }

    &__panel {
        margin: 0 0 100px 0;
    }
}
