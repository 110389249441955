@import "../../utils.scss";

.menu-link {
    padding: 0px 15px;
    font-size: 34px;
    display: inline-block;
    cursor: pointer;
    color: #aaa;
    @include colorTransition;

    &:hover {
        color: white;
    }
}
