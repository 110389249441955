@import "../../utils.scss";

$size: 200px;

.history-link {
    display: inline-block;
    margin: 10px 10px 80px 10px;
    width: $size;
    height: $size;
    text-align: center;
    cursor: pointer;

    &::selection {
        background: transparent;
    }

    &__image,
    &__click-image,
    &__image-container {
        width: $size;
        height: $size;
        border-radius: 50%;
    }

    &__image {
        position: absolute;
    }

    &__click-image {
        position: relative;
        opacity: 0;
        @include prefix(transition, all 150ms linear, webkit moz o ms);

        &:hover {
            opacity: 1;
            box-shadow: 0 0 0 2px #FFF, 0 0 0 6px #000;
        }
    }

    &__title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 25px;
        line-height: 130%;
    }

    &__year {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
    }
}
